import Head from "next/head";

interface CustomHeadInterface {
 title?: string;
 subtitle?: string;
}

export const CustomHead = ({title, subtitle}: CustomHeadInterface) => {
  const defaultTitle = "Candao";
  let customTitle;
  if (!title) {
    customTitle = defaultTitle;
  } else {
    customTitle = title;
  }

  if (!title && subtitle) {
    customTitle += " | " + subtitle;
  } 

  return (
      <Head>
        <title>{customTitle}</title>
        <meta name="description" content="Candao protocol" />
        <link rel="icon" href="/favicon.ico" />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta name="robots" content="noindex"/>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
        />
    </Head>
  );
};
